.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 500px;
}

.carousel-wrapper {
  display: flex;
}

.carousel-slide {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
}

.carousel-slide img {
  max-width: 100%;
  height: auto;
  width: 90px;
  height: 140px;
  border-radius: var(--border-radius-small);
}

.carousel-slide.active {
  transform: scale(1.3); /* Bigger size for active slides */
  opacity: 1;
  z-index: 2;
}

.carousel-slide.prev,
.carousel-slide.next {
  transform: scale(1); /* Smaller size for adjacent slides */
  opacity: 0.85;
  z-index: 1;
}

.carousel-left-arrow,
.carousel-right-arrow {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  color: var(--color-text-primary);
  /* background-color: rgba(255, 255, 255, 0.7); */
  background-color: transparent;
  border-radius: var(--border-radius-medium);
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transform: translateY(-50%);
  z-index: 10;
}

.carousel-left-arrow {
  left: 0;
}

.carousel-right-arrow {
  right: 0;
}

.carousel-dots {
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot.active-dot {
  background-color: #717171;
}
