.baa {
  padding: 2.5% 15%;
}
.baa__section__one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.baa__section__one__start {
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: start;
}
.baa__section__one__start > h1 {
  color: #555;
  font-weight: 500;
  font-size: var(--font-size-h1);
}
.baa__section__one__start > p {
  font-size: var(--font-size-primary);
  line-height: var(--line-height-primary);
}
.baa__section__one__start > button {
  border: 0;
  background-color: var(--color-primary-accent);
  font-size: var(--font-size-secondary);
  width: fit-content;
  color: white;
  border-radius: 25px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.baa__section__one__end {
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: start;
}
.baa__section__one__end > img {
  object-fit: contain;
  width: 100%;
}

.baa__section__three {
  min-height: 60vh;
  /* padding: 2.5% 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.baa__section__three__start {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.baa__section__three__start > h3 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-secondary-accent);
}
.baa__section__three__start > h1 {
  width: 50%;
  font-size: 30px;
  color: #555;
  font-weight: 500;
}
.baa__faq {
  padding: 10px 0;
  border-top: 1px solid var(--color-secondary-accent);
}
.baa__faq__question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  color: #999;
}

.baa__faq__question > h1 {
  font-size: 13px;
  color: #999;
}
.baa__faq__question > span {
  display: flex;
  align-items: center;
  gap: 20px;
}
.baa__arrow {
  color: var(--color-secondary-accent);
  transition: transform 0.25s ease-out;
}
.baa__arrow.open {
  transform: rotateZ(90deg);
}
.baa__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.baa__faq__answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0 0;
}
.baa__faq__answer > p {
  line-height: 25px;
  font-size: 13px;
  margin: 10px 0;
  color: #555;
}
.baa__faq__answer.open {
  max-height: 200px;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

@media all and (max-width: 1024px) {
  .baa {
    padding: 5% 5%;
  }
  .baa__section__one {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .baa__section__one__start {
    width: 100%;
  }
  .baa__section__one__end {
    width: 100%;
  }
  .baa__section__three {
    padding: 5% 5%;
  }
  .baa__section__three__start {
    flex-direction: column;
    justify-content: start;
    margin-bottom: 30px;
  }
  .baa__section__three__start > h3 {
    margin-bottom: 20px;
  }
  .baa__section__three__start > h1 {
    width: 100%;
    font-size: 25px;
    margin-bottom: 20px;
  }
}
