.toaster-container {
  position: fixed;
  top: 10px;
  right: 20px;
  max-width: 400px;
  width: calc(100% - 20px);
  z-index: 100;
}

.toaster-container button {
  margin: 5px;
}

@media all and (max-width: 1024px) {
  .toaster-container {
    position: fixed;
    top: 10px;
    right: 20px;
    max-width: 100%;
    width: calc(100% - 20px);
  }
}
