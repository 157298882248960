.book__chapters__bg {
  background: var(--color-border);
}
.book__chapters {
  min-height: 100vh;
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}
.book__chapters__section__one {
  margin-top: 20px;
  display: flex;
  min-height: 80vh;
}
.book__chapters__nav {
  border-radius: 20px;
  background: white;
  width: 20%;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 85vh;
  overflow-y: scroll;
}
.book__chapters__nav > button {
  width: 100%;
  border-radius: 20px;
  background: var(--color-secondary-accent);
  color: white;
  border: 0;
  padding: 10px 10px;
  font-size: 18px;
  font-weight: 500;
}
.book__chapters__nav__chapters__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.book__chapters__nav__chapters {
  width: 100%;
  border-radius: 10px;
  background: #12121222;
  cursor: pointer;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px;
}
.book__chapters__nav__chapters.active {
  width: 100%;
  border-radius: 10px;
  background: var(--color-secondary-accent) 50;

  cursor: pointer;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px;
}
.book__chapters__nav__chapters > h3 {
  font-size: 13px;
  font-weight: 700;
  width: 100%;
}
.book__chapters__nav__chapters > p {
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
}
.book__chapters__body {
  border-radius: 20px;
  background: white;
  width: 80%;
  min-height: 60vh;
  padding: 30px 30px;
}
.book__chapters__body__header {
  padding: 10px 0;
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book__chapters__body__header__chaptertitle {
  font-size: 18px;
  font-weight: 500;
  color: #777;
  border: 0;
  background: inherit;
}
.book__chapters__body__header__chaptertitle:focus {
  outline: none;
}
.book__chapters__body__header > h3 {
  margin-left: auto;
}
.book__chapters__body__content {
  /* height: 100%; */
}
.book__chapters__view__title {
  width: 50%;
  font-size: var(--font-size-primary);
  line-height: 25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

@media all and (max-width: 1024px) {
  .book__chapters {
    padding: 5% 0;
  }
  .book__chapters__section__one {
    flex-direction: column;
  }
  .book__chapters__nav {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 5%;
    height: auto;
  }
  .book__chapters__body {
    width: 100%;
  }
  .book__chapters__nav > button {
    width: 100%;
  }
  .book__chapters__nav__chapters__wrap {
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
  }
  .book__chapters__nav__chapters {
    min-width: 250px;
    height: max-content;
  }
  .book__chapters__body {
    padding: 5% 5%;
  }
  .book__chapters__view__title {
    width: 80%;
  }
}
