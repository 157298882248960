.login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__container {
  padding: 20px 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-border-dimmed);
  border-radius: 23px;
  width: 403px;
  min-height: 524px;
  min-height: max-content;
}
.login__container > img {
  width: 190px;
  height: 37.15px;
  margin-bottom: 20px;
}
.login__container > h1 {
  font-weight: 600;
  font-size: var(--font-size-primary);
  line-height: 24px;
  color: var(--color-text-primary) 000;
  margin-bottom: 20px;
}
.login__container__social__signin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.login__container__social__signin > button {
  width: 190px;
  height: 37.15px;
  width: 151px;
  padding: 10px;
  font-size: var(--font-size-secondary);
  line-height: 24px;
  font-weight: 400;
  margin: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 0;
}
.login__container__social__signin > button > img {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.login__container__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login__container__form > input {
  margin: 5px 0;
  border-radius: 30px;
  height: 48px;
  width: 322px;
  border: 0;
  font-size: var(--font-size-secondary);
  line-height: 24px;
  padding: 10px;
  color: #868686;
  font-weight: 500;
}
.login__container__form > button {
  border-radius: 30px;
  margin-top: 40px;
  margin-bottom: 15px;
  width: 322px;
  height: 46px;
  border: 0;
  background: var(--color-secondary-accent);
  color: white;
}
.login__container__form > p {
  font-size: var(--font-size-secondary);
  line-height: 24px;
  text-align: center;
}
.login__container__form > p > span {
  font-weight: 600;
  cursor: pointer;
}
.login__container > p {
  font-size: var(--font-size-secondary);
  line-height: 24px;
  text-align: center;
  margin-top: auto;
}
.login__container > p > span {
  font-weight: 600;
  color: salmon;
  cursor: pointer;
}
.login__error__message {
  width: 100%;
  width: 322px;
  color: #721c24;
  font-size: var(--font-size-secondary);
  line-height: 25px;
  font-weight: 500;
  margin-top: 20px;
}

@media all and (max-width: 1024px) {
  .login__container {
    width: 95%;
  }
}
