.ac__dashbpard {
  width: 100%;
  height: 100%;
}

.ac__dashboard__section__one {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}
.ac__dashboard__section__one__div {
  width: 45%;
  min-height: 139px;
  border-radius: 21px;
  background: linear-gradient(
    89.94deg,
    var(--color-secondary-accent) -14.79%,
    var(--color-primary-accent) 112.67%
  );
  color: white;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ac__dashboard__section__one__div > span {
  display: flex;
  align-items: center;
}
.ac__dashboard__section__one__div > span > h1 {
  font-size: var(--font-size-h5);
}
.ac__dashboard__section__one__div__icon {
  margin-left: auto;
}
.ac__dashboard__section__one__div > span:last-of-type {
}
.ac__dashboard__section__one__div > span:last-of-type > h1 {
  font-size: var(--font-size-primary);
}
.ac__dashboard__section__one__div > span:last-of-type > button {
  border-radius: 10px;
  padding: 5px;
  background: white;
  color: var(--color-text-primary);
  border: 0;
  margin-left: auto;
}
.ac__dashboard__section__two {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  border-radius: 21px;
  background: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 30px 30px;
}
.ac__dashboard__section__two > span {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-primary-accent);
  font-weight: 600;
}
.ac__dashboard__section__two__icon {
  margin-left: auto;
}
.ac__dashboard__section__two > img {
  object-fit: contain;
  height: 200px;
  width: 200px;
}
.ac__dashboard__section__two > p {
  font-size: 13px;
  text-align: center;
}
.ac__dashboard__section__two > button {
  width: 193px;
  height: 44px;
  border-radius: 45px;
  border: 0;
  background: var(--color-secondary-accent);
  color: white;
}
.ac__dashboard__section__two__block {
  display: flex;
  align-items: start;
  gap: 20px;
  width: 100%;
}
.ac__dashboard__section__two__block > img {
  object-fit: contain;
  height: 150px;
}
.ac__dashboard__section__two__block__detail {
  width: 100%;
}
.ac__dashboard__section__two__wrap {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
}
.ac__dashboard__section__two__wrap__button {
  padding: 10px 20px;
  border-radius: 20px;
  border: 0;
  color: black;
}
.ac__dashboard__section__two__wrap__button.one {
  background: var(--color-secondary-accent);
  color: white;
}
.ac__dashboard__section__two__wrap__button.two {
  background: white;
  color: var(--color-secondary-accent);
  border: 1px solid var(--color-secondary-accent);
}
.ac__dashboard__section__two__block__detail__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.ac__dashboard__section__two__block__detail__header > h1 {
  font-size: 25px;
  font-weight: 700;
}
.ac__dashboard__section__two__block__detail__select {
  border: 1px solid #aaa;
  padding: 5px;
  border-radius: 5px;
  margin-left: auto;
}
.ac__dashboard__section__two__block__detail__content {
  display: flex;
  gap: 100px;
  align-items: center;
  margin: 30px 0;
}
.ac__dashboard__section__two__block__detail__content > article {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ac__dashboard__section__two__block__detail__content > article > p {
  font-size: 13px;
  font-weight: 500;
}
.ac__dashboard__section__two__block__detail__content > article > h1 {
  font-size: 20px;
  font-weight: 600;
}
.ac__modal__buy {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ac__modal__buy > p {
  font-weight: 600;
}
.ac__modal__buy__options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ac__modal__buy__option {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--color-secondary-accent) 30;
}
.ac__modal__buy__option > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ac__modal__buy__option > h3 {
  font-weight: 400;
  font-size: var(--font-size-primary);
}

@media all and (max-width: 1024px) {
  .ac__dashboard__section__one {
    padding: 0 5%;
  }
  .ac__dashboard__section__one__div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .ac__dashboard__section__two {
    box-shadow: none;
  }
  .ac__dashboard__section__two__block {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .ac__dashboard__section__two__block__detail__header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: start;
    gap: 10px;
  }
  .ac__dashboard__section__two__block__detail__header > h1 {
    font-size: 18px;
    font-weight: 700;
  }
  .ac__dashboard__section__two__block__detail__content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 10px 0;
  }
  .ac__dashboard__section__two__block__detail__content > article > p {
    font-size: var(--font-size-secondary);
    font-weight: 500;
  }
  .ac__dashboard__section__two__block__detail__content > article > h1 {
    font-size: var(--font-size-primary);
    font-weight: 600;
  }
  .ac__dashboard__section__two__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
  .ac__dashboard__section__two__wrap__button.one {
    width: 48%;
  }
  .ac__dashboard__section__two__wrap__button.three {
    width: 48%;
  }
  .ac__dashboard__section__two__wrap__button.three {
    width: 100%;
  }
  .ac__dashboard__section__two__block__detail__select {
    width: 100%;
    margin-left: none;
  }
}
