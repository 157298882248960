/* Skeleton loading animation */
.account-dashboard-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.account-dashboard-skeleton-tab {
  width: 45%;
  height: 145px;
}

/* Glimmer effect */
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media all and (max-width: 1024px) {
  .account-dashboard-skeleton-tab {
    width: 100%;
  }
}
