.contract {
  border-radius: 25px;
  background: white;
  padding: 30px 30px;
  margin: 0 15%;
}
.contract__section__one {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.contract__section__one > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 25px;
}
.contract__section__one > p {
  font-size: var(--font-size-secondary);
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 20px;
}
.contract__section__two {
}
.contract__section__two > form > h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
.contract__section__two > form > span {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.contract__section__two > form > span > button {
  background: var(--color-secondary-accent);
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 30px;
}
.contract__section__two__form__two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}
.contract__section__two__form__input__block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 49%;
}

.contract__section__two__form__input__block > label {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 1px;
}
.contract__section__two__form__input__block > input {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
}
.contract__section__two__form__input__block > select {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
}
.contract__section__two__form__input__block__textarea2 {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
  width: 100%;
  min-height: 200px;
  margin-bottom: 20px;
}
.contract__section__two__form__input__block__textarea {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
}
.contract__section__two__form__input__block.entire--span {
  width: 100%;
}
.contract__section__two__form__input__block.entire--span > h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
}
.contract__section__two__form__input__block.one--third {
  width: 28%;
}

@media all and (max-width: 767px) {
  .contract {
    border-radius: 25px;
    padding: 30px 30px;
    margin: 0 0;
  }
  .contract__section__two__form__input__block {
    width: 100%;
  }
  .contract__section__two__form__input__block.one--third {
    width: 60%;
  }
  .contract__section__two > form > span {
    justify-content: start;
  }
}
