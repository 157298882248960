.create__novel {
  border-radius: 25px;
  background: white;
  padding: 30px 30px;
}
.create__novel > h1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #aaa;
}
.create__novel__upload {
  display: flex;
  gap: 30px;
  align-items: end;
  margin-bottom: 30px;
}
.create__novel__upload img {
  object-fit: contain;
  width: 50px;
}
.create__novel__upload > div {
  width: 150px;
  height: 200px;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create__novel__upload > div > input {
  display: none;
}
.create__novel__upload > button {
  background: var(--color-primary-accent);
  color: white;
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 30px;
}
.create__novel > form > button {
  background: var(--color-secondary-accent);
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 30px;
}
.create__novel__form__input__block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.create__novel__form__input__block > label {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin-bottom: 1px;
}
.create__novel__form__input__block > input {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
}
.create__novel__form__input__block > select {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
}
.create__novel__form__input__block__textarea {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: white;
  padding: 10px 15px;
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
}

@media all and (max-width: 1024px) {
  .create__novel {
    border-radius: 25px;
    padding: 30px 30px;
    margin: 0 0;
  }
  .create__novel__form__input__block {
    width: 100%;
  }
}
