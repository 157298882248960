.edit__book {
  border-radius: 25px;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 2.5%;
}
.edit__book__upload {
  display: flex;
  gap: 30px;
  align-items: end;
  margin-bottom: 30px;
}
.edit__book__upload > div {
  width: 200px;
}
.edit__book__upload > div > input {
  display: none;
}
.edit__book__upload > div > label {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin-bottom: 1px;
}
/* .edit__book__upload > div {
  width: 150px;
  height: 200px;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* .edit__book__upload > div > input {
  display: none;
}
.edit__book__upload > button {
  background: var(--color-primary-accent);
  color: white;
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 30px;
} */
.edit__book > form > button {
  background: var(--color-secondary-accent);
  font-size: 15px;
  border-radius: 20px;
  padding: 10px 30px;
}
.edit__book__form__input__block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.edit__book__form__input__block > label {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin-bottom: 1px;
}
.edit__book__form__input__block > input {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: inherit;
  padding: 10px 15px;
}
.edit__book__form__input__block > select {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: inherit;
  padding: 10px 15px;
}
.edit__book__form__input__block__textarea {
  border: 1px solid var(--color-secondary-accent);
  border-radius: 10px;
  background: inherit;
  padding: 10px 15px;
  width: 100%;
  min-height: 300px;
  margin-bottom: 20px;
}

.image__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px dashed var(--color-secondary-accent);
  min-height: 200px;
  cursor: pointer;
  border-radius: 5px;
  gap: 10px;
  padding: 5px 5px;
}
.image__upload__label {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 11px;
  color: var(--tertiary-text-color);
  background: #e9f0ff;
  padding: 5px;
}
.image__upload__label > span {
  margin-left: auto;
}
.input__preview {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media all and (max-width: 1024px) {
  .edit__book {
    border-radius: 25px;
    padding: 10px 10px;
    margin: 0 0;
  }
  .edit__book__form__input__block {
    width: 100%;
  }
  /* .edit__book__upload > div {
    width: 100%;
  } */
  .image__upload {
    width: 100%;
  }
}
