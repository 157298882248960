.text-editor {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: 20px auto;
}

.toolbar {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.toolbar button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #999;
}
.toolbar button.active {
  background-color: var(--color-secondary-accent);
}

.editor {
  flex: 1;
  /* min-height: 600px; */
  min-height: 55vh;
  outline: none;
}

.word-count {
  /* margin-top: 10px; */
  font-weight: 500;
  font-size: var(--font-size-secondary);
}

.save-button,
.preview-button {
  margin-top: 10px;
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid var(--color-secondary-accent);
  background-color: transparent;
  border-radius: 25px;
  color: var(--color-secondary-accent);
  min-width: 100px;
}
.export-button {
  margin-top: 10px;
  padding: 10px 15px;
  cursor: pointer;
  border: 0;
  background-color: var(--color-secondary-accent);
  color: white;
  border-radius: 25px;
  min-width: 100px;
}

.export-button:hover,
.preview-button:hover {
  opacity: 0.7;
}

.preview-button {
  margin-left: 10px;
}

.preview-modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.preview-content {
  width: 70%;
  min-height: 70vh;
  background: #fff;
  color: var(--color-text-primary);
  padding: 20px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.close-button {
  padding: 5px 10px;
  cursor: pointer;
  border: 0;
  color: white;
  background-color: var(--color-secondary-accent);
  border-radius: 5px;
}

.close-button:hover {
  opacity: 0.7;
}
.text__editor__cta {
  display: flex;
  justify-content: space-between;
}
.text__editor__cta__end {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media all and (max-width: 1024px) {
  .text__editor__cta {
    flex-direction: column;
    justify-content: start;
  }
  .text__editor__cta__end {
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
  }
  .word-count {
    margin-top: 0;
  }
  .preview-content {
    width: 95%;
    height: 100dvh;
    padding: 5% 2.5%;
    overflow-y: scroll;
  }
}
