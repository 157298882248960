.modal__delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.modal__delete > h1 {
  font-size: 18px;
  font-weight: 600;
  font-size: var(--font-size-h5);
}
.modal__delete > p {
  font-size: var(--font-size-secondary);
  font-weight: 400;
}
.modal__delete > p {
  font-size: var(--font-size-secondary);
  font-weight: 400;
}
.modal__delete > img {
  object-fit: contain;
  width: 200px;
}
.modal__delete > textarea {
  width: 100%;
  height: 100px;
}
.modal__delete__button__wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}
.modal__delete__button__wrap > button {
  padding: 10px 20px;
  border: 0;
  border-radius: 25px;
  background-color: transparent;
  color: var(--color-primary-accent);
  border: 1px solid var(--color-primary-accent);
  min-width: 150px;
}
.modal__delete__button__wrap > button:last-of-type {
  padding: 10px 20px;
  border: 0;
  border-radius: 25px;
  background-color: var(--color-primary-accent);
  color: white;
  min-width: 150px;
}
.modal__delete > button {
  padding: 10px 20px;
  border: 0;
  border-radius: 25px;
  background-color: #721c24;
  min-width: 200px;
}
