/* Skeleton loading animation */
.book-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.book-skeleton-title {
  width: 100%;
  height: 60px;
}
.book-skeleton-span {
  width: 200px;
  height: var(--font-size-secondary);
}

.book-skeleton-synopsis {
  width: 100%;
  height: 400px;
}
.book-skeleton-button {
  width: 100px;
  height: 40px;
}
.book-skeleton-cover-image {
  width: 20%;
  height: 400px;
}

/* Glimmer effect */
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media all and (max-width: 1024px) {
  .book-skeleton-synopsis {
    width: 100%;
    height: 400px;
  }
  .book-skeleton-span {
    width: 100px;
  }
  .book-skeleton-cover-image {
    width: 50%;
    height: 300px;
  }
}
