.modal__plan__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal__plan__container > h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}
.modal__plan__container > button {
}
.modal__plan__checkbox__container__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}
.modal__plan__checkbox__container {
  width: 45%;
  display: flex;
  align-items: center;
  gap: 30px;
  border: 1px solid var(--color-primary-accent);
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.modal__plan__checkbox__container__label {
  width: 80%;
  font-size: var(--font-size-secondary);
  font-weight: 600;
}
.modal__plan__checkbox__container__check {
  width: 20%;
  font-size: 22px;
  font-weight: 600;
  color: var(--color-primary-accent);
}
