/* FloatingNav.css */

/* Floating Nav Styles */
.floating-nav {
  position: fixed;
  right: 27%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.floating-nav button {
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: black;
}

/* Side Tray Styles */
.side-tray {
  position: fixed;
  display: none;
  right: 30%;
  top: 10%;
  width: 400px;
  height: 90%;
  background-color: #fff;
  padding: 20px;
  z-index: 10001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.side-tray.open {
  display: block;
  right: 30%;
}

.side-tray .close-btn {
  background-color: transparent;
  font-size: 16px;
  color: var(--color-text-secondary);
  cursor: pointer;
  border: none;
}

.side-tray ul {
  list-style-type: none;
  padding: 0;
  margin-top: var(--spacing-medium);
}
.side-tray h4 {
  font-size: var(--font-size-h4);
  font-weight: 600;
  margin-top: 40px;
}
.side-tray-chapters-wrap {
  height: 80vh;
  overflow-y: auto;
}
.side-tray-chapters-wrap-li {
  padding: 10px 0;
  line-height: var(--line-height-primary);
  padding: var(--spacing-medium) 0;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-secondary);
  color: var(--color-text-secondary);
}
.side-tray-chapters-wrap-li.active {
  color: var(--color-primary-accent);
}

.side-tray-chapters-wrap-li:hover {
  text-decoration: underline;
  color: var(--color-primary-accent);
}

@media all and (max-width: 1024px) {
  .floating-nav {
    right: 5%;
    top: 50%;
  }

  .side-tray.open {
    right: 0;
  }
  .side-tray {
    width: 80%;
    top: 0;
    height: 100%;
  }
}
