.genre {
  background: var(--color-border);
}
.genre__section {
  min-height: 100vh;
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}
.genre__section__one {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
}
.genre__section__one__nav {
  width: 20%;
  position: -webkit-sticky;
  position: sticky;
  background: white;
  border-radius: 20px;
  padding: 25px 20px;
}
.genre__section__one__nav > h1 {
  color: var(--color-secondary-accent);
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 800;
  line-height: 24.2px;
  text-align: left;
}
.genre__section__one__nav__options__h3 {
  margin-bottom: 15px;
  font-size: var(--font-size-secondary);
  font-weight: 500;
  color: #777;
  line-height: 16.94px;
  padding: 10px 20px;
  cursor: pointer;
}
.genre__section__one__nav__options__h3.active {
  background: var(--color-primary-accent);
  color: white;
  /* padding: 10px 20px; */
  border-radius: 8px;
}

.genre__section__one__body {
  width: 80%;
  background: white;
  border-radius: 20px;
  padding: 20px 20px;
}
.genre__section__one__body__div1 {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
  margin-bottom: 15px;
  background: #f6f5f5;
  border-radius: 11px;
  padding: 10px;
}
.genre__section__one__body__div1 > h1 {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  line-height: 16.94px;
  color: #555;
}
.genre__section__one__body__div1 > span {
  display: flex;
  align-items: center;
  gap: 0px;
}
.genre__section__one__body__div1 > span > h1 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 16.94px;
  padding: 7.5px 15px;
  cursor: pointer;
  /* color: #777; */
}
.genre__section__one__body__display {
  padding: 30px 0;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  min-height: 100svh;
}
.genre__section__one__body__display__block {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 42.5%;
  cursor: pointer;
}
.genre__section__one__body__display__block > img {
  width: 128px;
  height: 181.07px;
  border-radius: var(--border-radius-large);
}
.genre__section__one__display__block__about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 128px);
}
.genre__section__one__display__block__about > h1 {
  font-size: var(--font-size-secondary);
  font-weight: 700;
  line-height: var(--line-height-primary);
  text-align: left;
}
.genre__section__one__display__block__about > span {
  display: flex;
}
.genre__section__one__display__block__about > span > h1 {
  font-size: var(--font-size-secondary);
}
.genre__section__one__display__block__about > span > h2 {
  font-size: var(--font-size-secondary);
  color: var(--color-text-secondary);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.genre__section__one__display__block__about > p {
  font-size: var(--font-size-secondary);
  font-weight: 300;
  line-height: var(--line-height-primary);
}
.genre__section__one__display__block__about > article {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.genre__section__one__display__block__about > article > h1 {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  line-height: 14.52px;
  color: var(--color-secondary-accent);
}
.genre__nav__active {
  border-radius: 20px;
  background-color: var(--color-primary-accent);
  color: white;
  padding: 7.5px 15px;
}
@media all and (max-width: 1024px) {
  .genre__section {
    padding: 5% 5%;
  }
  .genre__section__one {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
  }
  .genre__section__one__nav {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    border-radius: 20px;
    padding: 15px 20px;
  }
  .genre__section__one__nav__options__wrap {
    width: 100%;
    overflow-x: scroll;
  }
  .genre__section__one__nav__options {
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
  }
  .genre__section__one__nav__options__h3.active {
    flex: 1;
    border-radius: 20px;
  }

  .genre__section__one__body {
    width: 100%;
    padding: 5% 5%;
  }
  .genre__section__one__body__display__block {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
