.scroll-to-top {
  position: fixed;
  bottom: 30vh;
  right: 8%;
  z-index: 1000;
}

.scroll-to-top-btn {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background-color: #f5863433;
  color: #f58634;
  border: none;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s;
}

.scroll-to-top-btn:hover {
}

.scroll-to-top-btn {
  opacity: 0.8;
}

@media all and (max-width: 1024px) {
  .scroll-to-top {
    position: fixed;
    bottom: 15vh;
    right: 8%;
    z-index: 1000;
  }
  .scroll-to-top-btn {
    height: 50px;
    width: 50px;
  }
}
