.ac__profile {
  width: 100%;
  min-height: 80vh;
  background: white;
  padding: 75px 75px;
}
.ac__profile > h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.ac__profile__section__one {
  display: flex;
  align-items: start;
  border-top: 1px solid #ccc;
}
.ac__profile__section__one__start {
  width: 30%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ac__profile__section__one__avatar > img {
  object-fit: cover;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.ac__profile__section__one__start > h5 {
  font-size: var(--font-size-h5);
  font-weight: 600;
}
.ac__profile__section__one__end__input {
  width: 40%;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-small);
}
.ac__profile__section__one__start > button {
  background: transparent;
  border: 1px solid var(--color-primary-accent);
  color: var(--color-primary-accent);
  font-size: var(--font-size-h6);
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
}

.ac__profile__section__one__end {
  width: 70%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ac__profile__section__one__end__label {
  font-size: var(--font-size-h6);
  font-weight: 600;
  margin-bottom: 10px;
}
.ac__profile__section__one__button__wrap {
  display: flex;
  gap: 10px;
}
.ac__profile__section__one__button {
  background: var(--color-secondary-accent);
  border: 0;
  color: white;
  font-size: var(--font-size-h6);
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
}
.ac__profile__section__one__button--cancel {
  background: transparent;
  border: 1px solid var(--color-secondary-accent);
  color: var(--color-secondary-accent);
  font-size: var(--font-size-h6);
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
}
@media all and (max-width: 1024px) {
  .ac__profile {
    padding: 5% 5%;
  }
  .ac__profile__section__one {
    display: flex;
    flex-direction: column;
  }
  .ac__profile__section__one__start {
    width: 100%;
  }
  .ac__profile__section__one__end {
    width: 100%;
  }
  .ac__profile__section__one__end__input {
    width: 100%;
  }
}
