.create__bg {
  background: var(--color-border);
}
.create {
  min-height: 100vh;
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}
.create__section__one {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.create__section__one__image {
  object-fit: contain;
  width: 300px;
  margin-bottom: 50px;
}
.create__section__one__button {
  background: var(--color-primary-accent);
  color: white;
  border-radius: 20px;
  padding: 10px;
  border: 0;
  font-size: 18px;
}

@media all and (max-width: 1024px) {
  .create {
    padding: 5% 5%;
  }
  .create__section__one__button {
    width: 100%;
  }
}
