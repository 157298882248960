.book__bg {
  background: var(--color-border);
}
.book {
  min-height: 100vh;
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}
.book__section__one {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.book__section__one > img {
  width: 20%;
  height: 400px;
  object-fit: contain;
}
.book__section__one__end > h1 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
.book__section__one__end > span {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  font-size: var(--font-size-primary);
  font-weight: 200;
  color: #777;
}
.book__section__one__end > span > h3 {
  color: var(--color-text-primary);
  font-weight: 500;
}
.book__section__one__end__cta {
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* gap: 10px; */
}
.book__section__one__end__cta__bt {
  border: 0;
  border-radius: 20px;
  padding: 7.5px 15px;
  font-size: var(--font-size-secondary);
  cursor: pointer;
  min-width: 100px;
}
.book__section__one__end__cta__bt.read {
  background: var(--color-secondary-accent);
  color: white;
}
.book__section__one__end__cta__bt.add {
  background: transparent;
  border: 1px solid var(--color-secondary-accent);
  color: var(--color-secondary-accent);
}
.book__section__one__end__cta__bt.like {
  border: 1px solid var(--color-secondary-accent);
  background: transparent;
  color: var(--color-secondary-accent);
  display: flex;
  align-items: center;
  gap: 15px;
}
.book__section__two {
  padding: 30px 30px;
  background: white;
  margin-top: 20px;
}
.book__section__two__menu {
  display: flex;
  align-items: center;
  gap: 15px;
}
.book__section__two__menu__button {
  border-radius: 29px;
  border: 0;
  font-weight: 600;
  padding: 5px 20px;
  color: var(--color-text-primary);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
}
.book__section__two__menu__button--active {
  border-radius: 29px;
  border: 0;
  font-weight: 600;
  padding: 5px 20px;
  color: var(--color-primary-accent);
  font-size: 15px;
  cursor: pointer;
  border: 1px solid var(--color-primary-accent);
  background: transparent;
}
.book__section__two__menu > button:last-of-type {
  border: 0;
}
.book__section__two__synopsis {
}
.book__section__two__synopsis > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__synopsis > p {
  line-height: 30px;
  font-size: 15px;
}
.book__section__two__tags > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__tags > span {
  display: flex;
  align-items: center;
  gap: 15px;
}
.book__section__two__tags > span > button {
  border-radius: 15px;
  font-weight: 600;
  border: 0;
  background: var(--color-secondary-accent) 5c;
  color: var(--color-secondary-accent);
  font-size: var(--font-size-secondary);
  padding: 7.5px 15px;
}
.book__section__two__gifts {
}
.book__section__two__gifts > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__gifts__span {
  display: flex;
  border-radius: 36px;
  border: 2px solid var(--color-primary-accent);
  padding: 10px;
  justify-content: space-between;
}
.book__section__two__gifts__span > span {
  display: flex;
  align-items: center;
  gap: 5px;
}
.book__section__two__gifts__span > span > img {
  object-fit: contain;
  height: 40px;
}
.book__section__two__gifts__span > button {
  background: var(--color-secondary-accent);
  color: white;
  border-radius: 35px;
  border: 0;
  padding: 10px 20px;
  font-size: 13px;
  margin: 0;
}
.book__section__two__ratings {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
}
.book__section__two__ratings__start {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}
.book__section__two__ratings__start > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book__section__two__ratings__start > div > h3 {
  font-size: var(--font-size-primary);
  font-weight: 500;
}
.book__section__two__ratings__start > div > span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17.5px;
  color: var(--color-primary-accent);
}
.book__section__two__ratings__end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  width: 50%;
}
.book__section__two__ratings__end > span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  color: var(--color-primary-accent);
}
.book__section__two__ratings__end__bt {
  margin: 0;
  font-size: var(--font-size-secondary);
  cursor: pointer;
  padding: 10px 20px;
  background: var(--color-secondary-accent);
  color: white;
  border-radius: 35px;
  border: 0;
}
.book__section__two__ratings__end > p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.book__section__two__recommendations {
}
.book__section__two__recommendations > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__recommendations__span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  padding: 30px 0;
}
.book__section__two__recommendations__span > div {
  width: 12.5%;
  margin: 10px 0;
}
.book__section__two__recommendations__span > div > img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.book__section__two__recommendations__span > div > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.book__section__two__recommendations__span > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.book__section__two__recommendations__span > div > span > h3 {
  font-size: var(--font-size-secondary);
  line-height: 20px;
  color: var(--color-text-secondary);
}
.book__section__two__recommendations__span > div > span > h4 {
  font-size: var(--font-size-secondary);
  line-height: 20px;
  color: var(--color-secondary-accent);
}
.book__section__two__reviews {
}
.book__section__two__reviews > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__reviews > div {
  padding: 10px 0;
  border-bottom: 1px solid #c8c8c8;
}
.book__section__two__reviews > div > h1 {
  font-size: var(--font-size-secondary);
  margin-bottom: 10px;
}
.book__section__two__reviews > div > p {
  font-size: 13px;
  line-height: 20px;
}
.book__section__two__reviews > div > span {
  display: flex;
  justify-content: space-between;
}
.book__section__two__reviews > div > span > h3 {
  color: var(--color-border);
  margin: 10px 0;
  font-size: var(--font-size-secondary);
}
.book__section__two__reviews > div > span > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.book__section__two__reviews__thumbsup {
  color: var(--color-secondary-accent);
}
.book__section__two__reviews__thumbsdown {
  color: red;
}
.book__section__two__catalog {
  padding: 10px 0;
  border-bottom: 1px solid #c8c8c8;
}
.book__section__two__catalog > h1 {
  margin: 20px 0;
  font-size: 22.5px;
}
.book__section__two__catalog__chapter {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.book__section__two__catalog__chapter > h1 {
  margin: 10px 0;
  font-size: 17px;
}
.book__section__two__catalog__chapter > h2 {
  color: var(--color-primary-accent);
  font-size: 15px;
  margin: 10px 0;
}
.book__section__two__catalog__chapter > h3 {
  font-size: 18px;
  color: var(--color-secondary-accent);
  width: 100%;
  text-align: right;
  margin: 10px 0;
  cursor: pointer;
}
.book__section__two__catalog__chapter > p {
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0;
}
.book__section__two__catalog__chapter > h4 {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 0;
}
.book__modal__gift {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.book__modal__gift__start {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.book__modal__gift__start > div {
  width: 25%;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.book__modal__gift__start > div > img {
  object-fit: contain;
  height: 50px;
}
.book__modal__gift__start > div > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.book__modal__gift__start > div > span > img {
  object-fit: contain;
  height: 15px;
}
.book__modal__gift__start > div > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.book__modal__gift__end {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.book__modal__gift__end > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.book__modal__gift__end > span > img {
  object-fit: contain;
  height: 15px;
}
.book__modal__review {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* align-items: center; */
}
.book__modal__review > h1 {
  font-size: 20px;
}
.book__modal__review > textarea {
  height: 200px;
  color: #ccc;
  border-color: #ccc;
  padding: 10px;
}
.book__modal__review > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book__modal__review__rating__start {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}
.book__modal__review__rating__start > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book__modal__review__rating__start > div > h3 {
  font-size: 13px;
}
.book__modal__review__rating__start > div > span {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: var(--font-size-secondary);
  color: var(--color-primary-accent);
}
.book__modal__review__rating__end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30%;
  border: 1px solid var(--color-primary-accent);
  border-radius: 5px;
  padding: 15px 15px;
}
.book__modal__review__rating__end > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
}
.book__modal__review__rating__end > h1 {
  font-size: 30px;
  font-weight: 700;
}
@media all and (max-width: 1024px) {
  .book {
    padding: 5% 5%;
  }
  .book__section__one {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .book__section__one > img {
    width: 50%;
    height: 300px;
    object-fit: contain;
  }
  .book__section__one__end {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .book__section__one__end > h1 {
    font-size: 25px;
  }

  .book__section__one__end > span {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    font-size: var(--font-size-secondary);
    font-weight: 200;
    color: #777;
  }

  .book__section__one__end__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .book__section__one__end__cta__bt {
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 13px;
    /* width: max-content; */
  }
  .book__section__two {
    padding: 5% 5%;
  }
  .book__section__two__menu {
    justify-content: center;
  }
  .book__section__two__synopsis > p {
    font-size: var(--font-size-secondary);
    line-height: 22.5px;
  }
  .book__section__two__ratings {
    padding: 30px 0;
    flex-direction: column;
    gap: 20px;
    justify-content: start;
  }
  .book__section__two__ratings__start {
    width: 100%;
  }
  .book__section__two__ratings__start > div > h3 {
    font-size: var(--font-size-secondary);
    font-weight: 500;
  }
  .book__section__two__ratings__start > div > span {
    gap: 10px;
    font-size: var(--font-size-secondary);
    color: var(--color-primary-accent);
  }
  .book__section__two__ratings__end {
    width: 100%;
  }
  .book__section__two__ratings__end > span {
    gap: 5px;
    font-size: var(--font-size-secondary);
  }
  .book__section__two__ratings__end > p {
    gap: 10px;
    font-size: 13px;
  }
  .book__section__two__tags > span {
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
  }
  .book__section__two__recommendations__wrap {
    width: 100%;
    overflow-x: scroll;
  }
  .book__section__two__recommendations__span {
    width: max-content;
    display: flex;
    flex-wrap: none;
    gap: 15px;
    padding: 0 0;
  }
  .book__section__two__recommendations__span > div {
    width: 150px;
  }
  .book__section__two__gifts__span > span {
    gap: 10px;
  }
  .book__section__two__gifts__span > button {
    border-radius: 25px;
    border: 0;
    padding: 5px 10px;
    font-size: var(--font-size-secondary);
  }
  .book__section__two__ratings__end__bt {
    margin: 0;
    font-size: var(--font-size-secondary);
  }
  .book__section__two__gifts__span > button {
    border-radius: 25px;
    border: 0;
    padding: 0 10px;
    font-size: var(--font-size-secondary);
    margin: 0;
  }
}
