.view__novels {
  background: var(--color-border);
}
.view__novels__section__one {
  padding: 2.5% 15%;
}
.view__novels__section__two {
  padding: 0 15%;
  padding-bottom: 2.5%;
  width: 100%;
  min-height: 100dvh;
}

.view__models__section__title > h1 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-secondary-accent);
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: left;
  /* cursor: pointer; */
}
.view__novels__section__title__arrow {
  cursor: pointer;
  font-weight: 600;
}
@media all and (max-width: 1024px) {
  .view__novels__section__one {
    padding: 5% 5%;
  }
  .view__novels__section__two {
    padding: 5% 5%;
  }
}
