.terms {
  min-height: 100vh;
  background-color: var(--color-border-dimmed);
  padding: 2.5% 30%;
}

.terms > h1 {
  font-size: var(--font-size-h5);
}
.terms > h3 {
  font-size: var(--font-size-primary);
  margin: var(--spacing-large) 0;
}
.terms > p {
  font-size: var(--font-size-secondary);
  margin: var(--spacing-large) 0;
  line-height: var(--line-height-primary);
}
@media all and (max-width: 1024px) {
  .terms {
    padding: 5% 5%;
  }
}
