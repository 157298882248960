.bp__bg {
  background: var(--color-border);
}
.bp {
  padding: 1.5% 30%;
  width: 100%;
  min-height: 100vh;
}

.bp__section__one {
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 1.5% 15%;
}
.bp__section__one > img {
  object-fit: cover;
  height: 250px;
}
.bp__section__one > h1 {
  font-size: 22.5px;
  font-weight: 700;
}
.bp__section__one > h3 {
  font-weight: 400;
  font-size: 18px;
}
.bp__section__two {
  background: #fff;
  padding: 1.5% 1.5%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.bp__section__two__chapter {
  font-size: 18px;
  color: var(--color-text-secondary);
  font-weight: 600;
  margin-bottom: 20px;
}
.bp__section__two__info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.bp__section__two__text {
  font-size: 15px;
  line-height: 40px;
  margin-bottom: 20px;
}
.bp__section__two__text > br {
  margin: 30px 0;
}
.bp__section__two > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: auto;
}
.bp__section__two > span > button {
  border: 1px solid var(--color-secondary-accent);
  background: transparent;
  color: var(--color-secondary-accent);
  border-radius: 35px;
  padding: 10px 20px;
  display: flex;
  align-self: center;
  text-align: center;
}
.bp__section__two > span > button:last-of-type {
  border: 0;
  background: var(--color-secondary-accent);
  color: white;
  border-radius: 35px;
  padding: 10px 20px;
  display: flex;
  align-self: center;
  text-align: center;
}

@media all and (max-width: 1024px) {
  .bp {
    padding: 5% 5%;
  }
  .bp__section__one {
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5% 5%;
  }
  .bp__section__two {
    padding: 5% 2%;
  }
  .bp__section__two__chapter {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
}
