.home {
  background: white;
}
.home__main {
  min-height: 100vh;
}

.home__section__one {
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}

.home__section__one__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.home__section__one__main__start {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home__section__one__main__start > button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 1var (--font-size-secondary); */
  height: 28.5px;
  border-radius: 24px;
  border: 0.75px solid var(--color-secondary-accent);
  background: transparent;
  color: var(--color-secondary-accent);
}
.home__section__one__main__start > button:last-of-type {
  background: var(--color-secondary-accent);
  color: white;
  width: 194px;
  height: 48px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 68px;
}
.home__section__one__main__start > h1 {
  font-size: 55px;
  font-weight: 800;
  line-height: 68px;
  text-align: left;
}
.home__section__one__main__start > span {
  display: flex;
  gap: 30px;
}
.home__section__one__main__start > span > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.02px;
  text-align: left;
  color: var(--color-primary-accent);
}
.home__section__one__main__start > span > h1:last-of-type {
  color: var(--color-text-primary);
}
.home__section__one__main__start > p {
  font-size: 15px;
  font-weight: 300;
  line-height: 25.02px;
  text-align: left;
}
.home__section__one__main__start__dropdown__trigger {
  position: absolute;
  padding: "10px 20px";
  cursor: "pointer";
  width: "150px" !important;
}
.home__section__one__main__start__dropdown__content {
  position: "absolute";
  background-color: "white";
  width: "150px" !important;
  z-index: 10;
  padding: "10px 0";
  margin-top: "30px";
}
.home__section__one__main__start__dropdown__content__block {
  background-color: "#668833" !important;
  color: "white";
  padding: "10px 20px";
  border: "none";
  cursor: "pointer";
  width: "100%";
  text-align: "center";
  font-size: "var(--font-size-secondary)";
}

.home__section__one__main__end {
  width: 40%;
  /* background: url("/src/assets/images/home__section__one__bg.png"); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 17px;
}
.home__section__one__main__end > img {
  width: 296.17px;
  height: 400px;
  border-radius: var(--border-radius-large);
}
.home__section__two {
  padding: 1.5% 15%;
  width: 100%;
  /* margin-top: -100px; */
  margin-top: -50px;
}
.home__section__two__start {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  padding: 30px 30px;
  background: url("/src/assets/images/home__section__two__banner.png");
  background-size: cover;
  border-radius: 26px;
}
.home__section__two__start__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  background: #ffffff99;
  border-radius: 22px;
  width: 292px;
  min-height: 115px;
}
.home__section__two__start__block > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: white;
}
.home__section__two__start__block > p {
  font-size: var(--font-size-secondary);
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.home__section__two__end {
  font-size: 24px;
  font-weight: 700;
  line-height: 68px;
  text-align: left;
  margin-bottom: 20px;
}
.home__section__two__end > h1 {
  font-size: 18px;

  font-weight: 700;
  line-height: 68px;
  text-align: left;
  margin: 20px 0;
}
.home__section__two__end__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}

.home__section__two__end__one {
  width: 24%;
  border-radius: 22.43px;
  /* background: url("/src/assets/images/home__section__one__bg.png"); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__section__two__end__one > div {
  width: 100%;
  min-height: 423px;
  border-radius: 15px;
  background: var(--color-border);
}
.home__section__two__end__one > div > div > div > div > img {
  width: 297.2px;
  height: 176.56px;
}
.home__section__two__end__one__carousel__details {
  margin: 0 var(--spacing-medium);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #121212;
}
.home__section__two__end__one > div > div > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.home__section__two__end__one > div > div > p {
  width: 100%;
  font-size: var(--font-size-secondary);
  font-weight: 400;
  line-height: var(--line-height-primary);
}
.home__section__two__end__one > div > div > span {
  display: flex;
  gap: 30px;
}
.home__section__two__end__one > div > div > span > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  font-weight: 400;
  line-height: 68px;
}
.home__section__two__end__two {
  width: 75%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__two__end__two > div {
  width: 17%;
  margin: 10px 0;
  cursor: pointer;
}
.home__section__two__end__two > div > img {
  object-fit: cover;
  height: 180px;
  width: 100%;
  border-radius: 10px;
}
.home__section__two__end__two > div > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__two__end__two > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__two__end__two > div > span > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__two__end__two > div > span > h4 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__three {
  display: flex;
  justify-content: space-between;
  padding: 1.5% 15%;
}
.home__section__three__one {
  width: 64%;
  background: var(--color-border);
  border-radius: 20px;
  padding: 20px 20px;
}
.home__section__three__one > h1 {
  font-size: 18px;
}
.home__section__three__one__wrap__bg {
  width: 100%;
}
.home__section__three__one__wrap__fg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.home__section__three__one__wrap__fg > div {
  width: 30%;
  text-align: center;
  cursor: pointer;
}
.home__section__three__one__wrap__fg__img__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__section__three__one__wrap__fg__img__wrap > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
}
.home__section__three__one__wrap__fg__img__wrap > img:last-of-type {
  width: 30px;
  height: 60px;
  object-fit: cover;
}

.home__section__three__one__wrap__fg > div > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
  margin-top: 10px;
}
.home__section__three__one__wrap__fg > div > p {
  font-size: var(--font-size-secondary);
  font-weight: 200;
  text-align: center;
}
.home__section__three__one__wrap__fg > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.home__section__three__one__wrap__fg > div > span > h3 {
  color: #999;
  font-size: var(--font-size-secondary);
  font-weight: 500;
  font-weight: 600;
}
.home__section__three__one__wrap__fg > div > span > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.home__section__three__one__wrap__fg > div > span > div > h2 {
  color: var(--color-secondary-accent);
  font-size: var(--font-size-secondary);
  font-weight: 500;
  font-weight: 600;
}
.home__section__three__one__wrap__fg > div > span > div > h3 {
  color: var(--color-text-primary);
  font-size: var(--font-size-secondary);
  font-weight: 500;
  font-weight: 600;
}
.home__section__three__one > h1 {
  margin-bottom: 30px;
}

.home__section__three__two {
  width: 32%;
  background: #f7ffef;
  border-radius: 20px;
  padding: 20px;
}
.home__section__three__two > h1 {
  margin-bottom: 20px;
  font-size: 18px;
}
.home__section__three__two__trending {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.home__section__three__two__trending__block {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.home__section__three__two__trending__block > h1 {
  font-size: var(--font-size-primary);
  font-weight: 600;
}
.home__section__three__two__trending__block > img {
  object-fit: cover;
  width: 90px;
}
.home__section__three__two__trending__block > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.home__section__three__two__trending__block > div > h1 {
  font-size: 15px;
  font-weight: 700;
}
.home__section__three__two__trending__block > div > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home__section__three__two__trending__block > div > span > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  color: var(--color-primary-accent);
}
.home__section__three__two__trending__block > div > span > h4 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
}
.home__section__four {
  display: flex;
  flex-direction: column;
  padding: 1.5% 15%;
}
.home__section__four__heading {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__heading h1 {
  font-size: 18px;
}
.home__section__four__heading__icon {
  margin-left: 10px;
  color: var(--color-secondary-accent);
}
.home__section__four__heading > h2 {
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home__section__four__display {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__four__display > div {
  width: 10%;
  margin: 10px 0;
}
.home__section__four__display > div > img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.home__section__four__display > div > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__four__display > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__display > div > span > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__four__display > div > span > h4 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__five {
  background: var(--color-border);
}
.home__section__four__alt {
  display: flex;
  flex-direction: column;
  padding: 1.5% 15%;
}
.home__section__four__alt__heading {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__alt__heading__icon {
  margin-left: 10px;
  color: var(--color-secondary-accent);
}
.home__section__four__alt__heading > h2 {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.home__section__four__alt__display {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__four__alt__display > div {
  width: 23%;
  margin: 10px 0;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.home__section__four__alt__display > div > img {
  object-fit: cover;
  height: 200px;
  width: 150px;
  border-radius: 10px;
}
.home__section__four__alt__display > div > div > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__four__alt__display > div > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__alt__display > div > div > span > h3 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__four__alt__display > div > div > span > h4 {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  line-height: 20px;
  color: var(--color-text-secondary);
}
.home__section__four__alt__display > div > div > p {
  font-size: var(--font-size-secondary);
  line-height: 25px;
  color: #555;
  margin-top: 10px;
}

@media all and (max-width: 1024px) {
  .home__section__one {
    padding: 5% 5%;
    border-radius: 0px 0px 120px 120px;
  }
  .home__section__one__main {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 50px;
  }
  .home__section__one__main__start {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .home__section__one__main__start > h1 {
    font-size: 35px;
    line-height: 50px;
    text-align: center;
  }
  .home__section__one__main__start > p {
    line-height: 25.02px;
    text-align: center;
  }
  .home__section__one__main__end {
    margin-top: 30px;
    width: 100%;
    padding: 20px;
    border-radius: 17px;
  }
  .home__section__one__main__end > img {
    width: 100%;
    height: 400px;
  }
  .home__section__two {
    padding: 5% 5%;
    margin-top: 0;
    padding-bottom: 0;
  }
  .home__section__two__start {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    border-radius: 50px;
    display: none;
  }
  .home__section__two__start__block {
    margin-bottom: 20px;
    padding: 15px 15px;
    background: #ffffff99;
    border-radius: 22px;
    width: 292px;
    min-height: 115px;
  }
  .home__section__two__end {
    margin: 0;
    padding: 0;
  }
  .home__section__two__end__section {
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2%;
  }

  .home__section__two__end__one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-large);
  }
  .home__section__two__end__one > div {
    width: 100%;
    min-height: 423px;
  }
  .home__section__two__end__one > div > p {
    width: 100%;
    text-align: center;
  }
  .home__section__two__end__one > div > div > p {
    font-size: var(--font-size-secondary);
  }
  .home__section__two__end__two {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .home__section__two__end__two > div {
    width: 32%;
    margin: 10px 0;
  }
  .home__section__two__end__two > div > img {
    height: 165px;
  }
  .home__section__three {
    flex-direction: column;
    justify-content: space-between;
    padding: 5% 0;
    margin: 0;
  }
  .home__section__three__one {
    width: 100%;
    border-radius: 0;
  }
  .home__section__three__one__wrap__bg {
    width: 100%;
    overflow-x: scroll;
  }
  .home__section__three__one__wrap__fg {
    width: max-content;
    justify-content: start;
  }
  .home__section__three__one__wrap__fg > div {
    width: 220px;
    padding: 0;
  }
  .home__section__three__one__wrap__fg > div > p {
  }
  .home__section__three__two {
    width: 100%;
    border-radius: 0;
  }
  .home__section__four {
    padding: 5% 5%;
  }
  .home__section__four__display {
    width: 100%;
  }
  .home__section__four__display > div {
    width: 32%;
    margin: 10px 0;
  }
  .home__section__four__alt {
    padding: 5% 5%;
  }
  .home__section__four__alt__display > div {
    width: 100%;
    margin: 10px 0;
    gap: 10px;
  }
}
