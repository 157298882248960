.ac__inbox {
  width: 100%;
  min-height: 100vh;
  background: white;
  padding: 75px 75px;
}
.ac__inbox > h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.ac__inbox__section__one {
  display: flex;
  align-items: start;
  border-top: 1px solid #ccc;
}
.ac__inbox__section__one__chats {
  width: 50%;
  min-height: 100vh;
  border-right: 1px solid #ccc;
  padding: 20px 20px;
}
.ac__inbox__section__one__chats__entries {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 25px 0;
}
.ac__inbox__section__one__chats__entries:hover {
  color: var(--color-primary-accent);
}
.ac__inbox__section__one__chat__message {
  width: 50%;
  padding: 20px 20px;
}
.ac__inbox__section__one__chat__message > h3 {
  font-size: var(--font-size-primary);
  font-weight: 600;
  margin-bottom: 20px;
}
.ac__inbox__section__one__chat__message > p {
  font-size: var(--font-size-secondary);
  line-height: 30px;
  font-weight: 400;
  margin: 20px 0;
}

@media all and (max-width: 1024px) {
  .ac__inbox {
    padding: 5% 5%;
  }
  .ac__inbox__section__one__chats {
    width: 100%;
    min-height: 100vh;
    border-right: 0;
    padding: 5% 5%;
  }
  .ac__inbox__section__one__chats.active {
    display: none;
  }
  .ac__inbox__section__one__chat__message {
    display: none;
    width: 100%;
    padding: 5% 5%;
  }
  .ac__inbox__section__one__chat__message.active {
    display: block;
    width: 100%;
    padding: 5% 5%;
  }
}
