.ac__transaction__section__one {
  width: 100%;
  min-height: 100vh;
  background: white;
  padding: 75px 75px;
}
.ac__transaction__section__one > h1 {
  font-weight: 500;
}
.ac__transaction__section__one__nav {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ac__transaction__section__two {
  /* min-height: 100svh; */
}
.ac__transaction__section__one__nav__menu {
  font-size: var(--font-size-primary);
  font-weight: 500;
  margin: 0;
  color: #777;
  cursor: pointer;
}
.ac__transaction__section__one__nav__menu--active {
  font-weight: 700;
  font-size: var(--font-size-primary);
  color: var(--color-primary-accent);
}

@media all and (max-width: 1024px) {
  .ac__transaction__section__one {
    padding: 5% 5%;
  }
  .ac__transaction__section__two {
    border-radius: 0;
    overflow-x: scroll;
    width: 100%;
  }
}
