.sup__header {
  background: white;
  width: 100%;
  padding: 1% 2.5%;

  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 53px;
}
.sup__header__logo {
  width: 190px;
  height: 35px;
  cursor: pointer;
}
@media all and (max-width: 1024px) {
  .sup__header {
    width: 100%;
    padding: 5% 5%;
  }
  .sup__header__logo {
    width: 150px;
    height: 32.5px;
  }
}
