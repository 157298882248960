.not__found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-medium);
}
.not__found > img {
  object-fit: contain;
  width: 150px;
}
.not__found__button {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: var(--color-primary-accent);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
