/* Skeleton loading animation */
.home-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 7.5px;
}

.home-skeleton-title {
  width: 100%;
  height: 60px;
}
.home-skeleton-genres {
  width: 200px;
  height: 40px;
}

.home-skeleton-description {
  width: 100%;
  height: 140px;
}
.home-skeleton-button {
  width: 150px;
  height: 40px;
}
.home-skeleton-image {
  width: 296.17px;
  height: 400px;
}

/* Glimmer effect */
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media all and (max-width: 1024px) {
  .home-skeleton-description {
    width: 100%;
    height: 120px;
  }
  .home-skeleton-genres {
    width: 100px;
    height: 40px;
  }
  .home-skeleton-image {
    width: 100%;
    height: 400px;
  }
}
