.sup__footer {
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 1% 15%;
  color: var(--color-text-secondary);
}
.sup__footer > p {
  font-size: var(--font-size-tertiary);
  cursor: pointer;
  font-weight: 400;
  line-height: 24px;
}

@media all and (max-width: 1024px) {
  .sup__footer {
    flex-wrap: wrap;
    gap: 30px;
    padding: 5% 5%;
  }
  .sup__footer > p {
    cursor: pointer;
    font-weight: 400;
    line-height: 24px;
    margin: 10px 0;
  }
}
