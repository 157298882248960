.contact {
  min-height: 100vh;
  background-color: var(--color-border-dimmed);
  padding: 2.5% 30%;
}
.contact > h1 {
  font-size: var(--font-size-h5);
}
.contact > h1 {
  font-size: var(--font-size-h5);
}
.contact > h3 {
  font-size: var(--font-size-primary);
  margin: var(--spacing-large) 0;
}
.contact > p {
  font-size: var(--font-size-secondary);
  margin: var(--spacing-large) 0;
  line-height: var(--line-height-primary);
}

@media all and (max-width: 1024px) {
  .contact {
    padding: 5% 5%;
  }
}
