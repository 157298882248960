.ac__stories__section__one {
  display: flex;
  width: 100%;
}
.ac__stories__section__one > button {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-secondary);
  background: var(--color-primary-accent);
  color: white;
  border-radius: 25px;
  padding: 10px 10px;
  border: 0;
}
.ac__stories__section__two {
  margin-top: 30px;
  background: white;
  width: 100%;
  border-radius: 21px;
  padding: 25px;
}
.ac__stories__section__two__header {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
}
.ac__stories__section__two__header__stories {
  width: 30%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__header__state {
  width: 20%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__header__chapter {
  width: 20%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__header__word {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__header__collection {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__header__actions {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-primary);
}
.ac__stories__section__two__entry {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}
.ac__stories__section__two__entry__stories__img {
  object-fit: contain;
  height: 125px;
}
.ac__stories__section__two__entry__stories {
  width: 30%;
  font-weight: 500;
  font-size: var(--font-size-secondary);

  display: flex;
  align-items: center;
  gap: 20px;
}
.ac__stories__section__two__entry__state {
  width: 20%;
  font-weight: 500;
  font-size: var(--font-size-secondary);
}
.ac__stories__section__two__entry__chapter {
  width: 20%;
  font-weight: 500;
  font-size: var(--font-size-secondary);
}
.ac__stories__section__two__entry__word {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-secondary);
}
.ac__stories__section__two__entry__collection {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-secondary);
}
.ac__stories__section__two__entry__actions {
  width: 10%;
  font-weight: 500;
  font-size: var(--font-size-secondary);

  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 22.5px;
}
.ac__stories__section__two__entry__actions__edit {
  cursor: pointer;
  color: #777;
}
.ac__stories__section__two__entry__actions__delete {
  cursor: pointer;
  color: #721c24;
}

@media all and (max-width: 1024px) {
  .ac__stories__section__one {
    padding: 0 5%;
  }
  .ac__stories__section__two {
    border-radius: 0;
    overflow-x: scroll;
    width: 100%;
  }
  .ac__stories__section__two__header {
    width: 350%;
  }
  .ac__stories__section__two__entry {
    width: 350%;
  }
}
