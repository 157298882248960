.shelf {
  background: var(--color-border);
}
.shelf__section {
  min-height: 100vh;
  background: var(--color-border);
  padding: 1.5% 15%;
  width: 100%;
  border-radius: 0px 0px 120px 120px;
}
.shelf__section__one {
  background: white;
  margin-top: 30px;
  border-radius: 29px;
  min-height: 100vh;
  padding: 20px 20px;
}
.shelf__section__one__header {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}
.shelf__section__one__header > h1 {
  color: var(--color-secondary-accent);
  font-size: 20px;
  font-weight: 800;
  line-height: 24.2px;
  text-align: left;
}
.shelf__section__one__header > span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.shelf__nav__button {
  padding: 5px 20px;
  border-radius: 29px;
  font-weight: 600;
  color: #999;
  font-size: 15px;
  cursor: pointer;
  background: white;
  border: 0;
  margin: 0;
}
.shelf__nav__button.active {
  background: var(--color-primary-accent);
  color: white;
}
.shelf__section__one__body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 25px;
  padding: 30px 0;
  text-align: center;
}
.shelf__section__one__body__shelf {
  width: 12.5%;
  margin: 10px 0;
}
.shelf__section__one__body__shelf > h1 {
  font-size: var(--font-size-secondary);
  line-height: 25px;
  text-align: start;
}
.shelf__section__one__body__shelf > img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.shelf__section__one__body__shelf > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shelf__section__one__body__shelf > span > h3 {
  font-size: var(--font-size-secondary);
  line-height: 20px;
  color: var(--color-text-secondary);
}
.shelf__section__one__body__shelf > span > h4 {
  font-size: var(--font-size-secondary);
  line-height: 20px;
  color: var(--color-text-secondary);
}

.shelf__section__one__body__reading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  padding: 30px 0;
  width: 100%;
}
.shelf__section__one__body__reading__block {
  display: flex;
  gap: 30px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  width: 49%;
  height: max-content;
  overflow: hidden;
}
.shelf__section__one__body__reading__block > img {
  object-fit: cover;
  height: 200px;
  border-radius: 5px;
}
.shelf__section__one__body__reading__block__end {
  text-align: start;
  flex: 1;
}
.shelf__section__one__body__reading__block__end > h1 {
  font-size: var(--font-size-primary);
  font-weight: 700;
}
.shelf__section__one__body__reading__block__author {
  margin: 20px 0;
  font-size: var(--font-size-secondary);
  font-weight: 500;
}

.shelf__section__one__body__reading__block__genre {
  font-size: var(--font-size-secondary);
  font-style: 500;
  display: flex;
  flex-wrap: wrap;
  color: var(--color-secondary-accent);
  gap: 15px;
}

.shelf__section__one__body__reading__block__details {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}
.shelf__section__one__body__reading__block__details > p {
  font-size: var(--font-size-secondary);
  line-height: 25px;
  font-weight: 400;
  color: #999;
}
.shelf__section__one__body__reading__block__details > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.shelf__section__one__body__reading__block__details > div > button {
  background: transparent;
  border: 1px solid var(--color-secondary-accent);
  color: var(--color-secondary-accent);
  border-radius: 30px;
  font-weight: 500;
  font-size: 11px;
  padding: 7.5px 10px;
  min-width: 150px;
  cursor: pointer;
}
.shelf__section__one__body__reading__block__details
  > div
  > button:last-of-type {
  border: 0;
  background: var(--color-secondary-accent);
  color: white;
}
.shelf__section__one__body__reading__block__progress {
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 500;
  font-size: var(--font-size-secondary);
  margin-top: 10px;
}

@media all and (max-width: 1024px) {
  .shelf__section {
    padding: 5% 2.5%;
  }
  .shelf__section__one__header > h1 {
    font-size: 20px;
  }
  .shelf__section__one__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    gap: 2.5%;
    padding: 30px 0;
  }
  .shelf__section__one__body__shelf {
    width: 45%;
    margin: 20px 5px;
    height: max-content;
  }

  .shelf__section__one__body__shelf > h1 {
    font-size: var(--font-size-secondary);
  }
  .shelf__section__one__body__reading__block {
    /* flex-direction: column; */
  }
  .shelf__section__one__body__reading__block__details {
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
  }
  .shelf__section__one__body__reading__block__details > p {
    font-size: var(--font-size-secondary);
    line-height: 20px;
  }
  .shelf__section__one__body__reading__block__details {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin: 10px 0;
  }
  .shelf__section__one__body__reading__block__details > div {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .shelf__section__one__body__reading__block__progress {
    gap: 10px;
    font-weight: 600;
    font-size: var(--font-size-secondary);
  }
  .shelf__section__one__body__reading__block {
    gap: 10px;
    display: flex;
    width: 100%;
  }
  .shelf__section__one__body__reading__block > img {
    object-fit: cover;
    height: 150px;
    width: 30%;
    border-radius: 5px;
  }
  .shelf__section__one__body__reading__block__details > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .shelf__section__one__body__reading__block__details > div > button {
    font-size: var(--font-size-secondary);
    padding: 5px 10px;
    min-width: auto;
  }
  .shelf__section__one__body__reading__block__end > h1 {
    font-size: 15px;
    font-weight: 700;
  }
  .shelf__section__one__body__reading__block__author {
    margin: 20px 0;
    font-size: var(--font-size-secondary);
    font-weight: 500;
  }
  .shelf__section__one__body__reading__block__genre {
    display: flex;
    color: var(--color-secondary-accent);
    font-size: var(--font-size-secondary);
  }
}
