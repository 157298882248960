.header {
  background: white;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  border-radius: 29px;
}
.header__logo {
  width: 190px;
  height: 37.15px;
  cursor: pointer;
}
.header__icons {
  font-size: 15.71px;
}
.header__ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: 150px;
}
.header__ul > li {
  font-size: 12.75px;
  font-weight: 600;
  line-height: var(--line-height-primary);
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 30px;
  cursor: pointer;
  color: #777;
}
.header__nav__active {
  color: var(--color-primary-accent) !important;
}
.header__icons {
  font-size: 12.75px;
  font-weight: 700;
  line-height: 14var (--font-size-primary);
  margin-right: 5px;
}
.header__end {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__end > .header__icons {
  font-weight: 600;
  font-size: 11.61px;
}
.header__end > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.header__end__sign {
  font-size: var(--font-size-secondary);
  font-weight: 600;
  cursor: pointer;
}
.header__end__account {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 5px;
  border-radius: 40px;
  border: 1px solid #ccc;
}
.header__end__account > img {
  object-fit: cover;
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.header__guest__profile {
  font-size: 30px;
  color: #999;
}
.header__end__account__badge {
  color: var(--color-primary-accent);
  font-size: 25px;
}
.header__menu {
  display: none;
  font-size: 35px;
  margin-left: auto;
}
.header__sidebar {
  display: none;
}
.header__sidebar__sign {
  border: 0;
  border-radius: 20px;
  padding: 10px 20px;
  color: white;
  background: var(--color-primary-accent);
  font-size: var(--font-size-primary);
  font-size: 600;
  min-width: 100px;
}
.header__sidebar.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: white;
}
.header__sidebar__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 5%;
  border-bottom: 1px solid #eee;
}
.header__sidebar__head__close {
  margin-left: auto;
  font-size: 30px;
}
.header__sidebar__body {
  padding: 5% 5%;
}
.header__sidebar__ul {
  list-style-type: none;
  flex-direction: column;
}
.header__sidebar__ul > li {
  font-size: var(--font-size-primary);
  font-weight: 600;
  line-height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 0;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  color: #777;
}
.header__icons {
  font-size: 20px;
}
.header__sidebar__account {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 5px;
  border-radius: 40px;
  border: 1px solid #ccc;
}
.header__sidebar__account > img {
  object-fit: cover;
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
.header__sidebar__account__badge {
  color: var(--color-primary-accent);
  font-size: 25px;
}
.header__error__message {
  color: #721c24;
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin-top: 20px;
}
.header__end__account__wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  top: 0;
}
/* Container for the dropdown */
.dropdown-container {
  position: relative;
  display: inline-block;
}

.author__dropdown {
  position: absolute;
  top: 45px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  z-index: 1000;
  min-width: 150px;
  border-radius: 5px;
}
.author__dropdown > ul {
  list-style-type: none;
}
.author__dropdown > ul > li {
  padding: 10px 10px;
  border-bottom: 1px solid #cfcfcf;
  color: var(--color-text-secondary);
}

/* Dropdown menu style */
.dropdown-menu {
  display: block;
  position: absolute;
  right: 0;
  top: 60px;
  background-color: white;
  color: var(--color-text-secondary);
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: max-content;
  transition: opacity 0.3s;
}

/* Dropdown menu items style */
.dropdown__menu__account {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 5px;
}
.dropdown__menu__account > img {
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}
.dropdown__menu__guest__profile {
  font-size: 40px;
  color: var(--color-text-secondary);
}
.dropdown-menu > span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin: 10px 0;
  min-width: 250px;
}
.dropdown-menu > span > h3 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdown-menu > span > button {
  border: 1px solid var(--color-primary-accent);
  padding: 10px 20px;
  border-radius: 25px;
  background: transparent;
  font-size: var(--font-size-secondary);
  color: var(--color-primary-accent);
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
.dropdown-menu > button {
  width: 100%;
  border: 0;
  padding: 10px 20px;
  border-radius: 25px;
  background: var(--color-primary-accent);
  color: white;
  cursor: pointer;
}
.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  color: var(--color-text-secondary);

  margin: 10px 0;
  cursor: pointer;
  font-size: var(--font-size-secondary);
  font-weight: 600;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}
.header__sidebar__menu {
  width: 100%;
  border-bottom: 1px solid #eee;
}
.header__sidebar__menu > span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-secondary);
  font-weight: 600;
  margin: 10px 0;
}
.header__sidebar__menu > span > h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-primary-accent);
}

.header__sidebar__menu > span > span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__sidebar__menu > span > span > button {
  font-size: var(--font-size-secondary);
  margin: 0;
  background: white;
  border: 1px solid var(--color-primary-accent);
  color: var(--color-primary-accent);
  padding: 10px 20px;
  border-radius: 30px;
}

.header__sidebar__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__sidebar__menu li {
  color: var(--color-primary-accent);
  margin: 10px 0;
  cursor: pointer;
  font-size: var(--font-size-secondary);
  font-weight: 600;
}

.header__sidebar__menu li:last-child {
  border-bottom: none;
}
@media all and (max-width: 1024px) {
  .header {
    width: 100%;
    padding: 5% 5%;
  }
  .header__logo {
    width: 150px;
    height: 32.5px;
  }
  .header__menu {
    display: block;
    font-size: 35px;
  }
  .header__ul {
    display: none;
  }
  .header__end {
    display: none;
  }
}
