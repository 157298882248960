.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  min-height: 60vh;
  max-width: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.section-tabs {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
}

.section-tab {
  background: #fff;
  color: #999;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
}

.section-tab.active {
  color: var(--color-secondary-accent);
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  display: inline-block;
  background: var(--color-secondary-accent) 30;
  color: var(--color-text-primary);
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  margin: 5px;
  font-size: var(--font-size-secondary);
}

.chip.selected {
  background: var(--color-secondary-accent) 22 !important;
  color: var(--color-secondary-accent);
}

.novel-modal-select-button {
  padding: 10px 10px;
  background: transparent;
  border: 1px solid var(--color-secondary-accent);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: var(--font-size-primary);
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.selected-chips-list {
  margin-top: auto;
}
.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.modal-button.confirm {
  background: var(--color-secondary-accent);
  color: #fff;
}

.modal-button.cancel {
  background: #ddd;
  color: var(--color-text-primary);
}

@media all and (max-width: 1024px) {
  .modal-content {
    min-height: 90vh;
    width: 90%;
    max-width: none;
  }
}
