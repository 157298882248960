.modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.modal.disable {
  display: none;
}
.modal__content {
  width: 25%;
  /* min-height: 40vh; */
  min-height: max-content;
  background: #fff;
  color: var(--color-text-primary);
  padding: 20px 20px;
  border-radius: 10px;
}
.modal__content__span {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
}
.modal__content__close {
  margin-left: auto;
  font-size: 25px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.modal__content__close:hover {
  color: #721c24;
}

@media all and (max-width: 1024px) {
  .modal__content {
    width: 90%;
    padding: 5% 5%;
  }
}
