.account__main {
  height: 10dvh;
}
.account {
  width: 100%;
  display: flex;
}
.account__navbar {
  width: 20%;
  padding: 5% 5%;
}
.account__navbar__ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  height: 100%;
}
.account__navbar__ul li {
  font-weight: 600;
  font-size: 17.5px;
  margin: 5px 0;
  cursor: pointer;
  min-width: max-content;
}

.account__outlet {
  width: 80%;
  background: var(--color-border);
  height: 95dvh;
  overflow-y: scroll;
  padding: 50px 50px;
}
.account__navbar__ul__li.activeId {
  color: var(--color-primary-accent);
}

@media all and (max-width: 1024px) {
  .account {
    flex-direction: column;
    height: 92.5svh;
  }
  .account__navbar {
    width: 100%;
    padding: 5% 5%;
    overflow-x: scroll;
  }
  .account__navbar__ul {
    flex: 1;
    flex-direction: row;
    gap: 30px;
    height: 100%;
    width: fit-content;
  }
  .account__outlet {
    width: 100%;
    padding: 5% 0;
    border-radius: 0;
  }
}
